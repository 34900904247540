import React from "react"

const Blurb = ({ title, text, svg }) => {
  return (
    <div class="flex">
      <div class="flex-shrink-0">
        <div class="flex items-center justify-center h-8 w-8 rounded-md bg-brand text-white p-1">
          {svg}
        </div>
      </div>
      <div class="ml-4">
        <dt class="text-lg leading-6 font-medium text-gray-900">{title}</dt>
        <dd class="mt-2 text-base text-gray-500">{text}</dd>
      </div>
    </div>
  )
}

export default Blurb
