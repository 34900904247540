import React from "react"
import Layout from "../components/layouts/Default"
import MainData from "../components/forms/MainData"
import Wizard from "../components/forms/Wizard"
import Blurb from "../components/common/Blurb"
import CheckboxCard from "../components/forms/CheckboxCard"
import Input from "../components/forms/Input"

export default function Page({ data, location }) {
  const goBack = () => {
    window.history.back()
  }

  const svg = {
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6 text-white"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
    ),

    buy: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        width="64"
        height="64"
        className="inline"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
        />
      </svg>
    ),

    rent: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        width="64"
        height="64"
        className="inline"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),

    house: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        width="64"
        height="64"
        className="inline"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
        />
      </svg>
    ),

    complex: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        width="64"
        height="64"
        className="inline"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
        />
      </svg>
    ),

    business: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        width="64"
        height="64"
        className="inline"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
        />
      </svg>
    ),

    square: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        stroke="currentColor"
        width="64"
        height="64"
        className="inline"
      >
        <path
          d="M6 20h12c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2z"
          fill="none"
          stroke="#4b5563"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),

    morgens: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
        <line x1="3" y1="21" x2="21" y2="21" />
        <path d="M12 9v-6l3 3m-6 0l3 -3" />
      </svg>
    ),
    mittags: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx="12" cy="12" r="4" />
        <path d="M3 12h1M12 3v1M20 12h1M12 20v1M5.6 5.6l.7 .7M18.4 5.6l-.7 .7M17.7 17.7l.7 .7M6.3 17.7l-.7 .7" />
      </svg>
    ),
    abends: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M3 17h1m16 0h1M5.6 10.6l.7.7m12.1-.7l-.7.7M8 17a4 4 0 0 1 8 0" />
        <line x1="3" y1="21" x2="21" y2="21" />
        <path d="M12 3v6l3 -3m-6 0l3 3" />
      </svg>
    ),
  }

  return (
    <Layout
      location={location}
      title="Suchauftrag | Döbbel Immobilien"
      name="Suchauftrag"
      desc=""
      hideHeader
      smallFooter
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <div className="flex flex-wrap">
              <div className="w-full mb-5">
                <div>
                  <h1 className="text-2xl font-bold md:text-3xl lg:text-4xl">
                    Jetzt Suchauftrag beauftragen
                  </h1>
                  <form
                    name="Suchauftrag"
                    method="POST"
                    data-netlify="true"
                    action="/nachricht-gesendet/"
                  >
                    <input type="hidden" name="form-name" value="Suchauftrag" />

                    <Wizard>
                      <div>
                        <div className="mt-8 mb-4">
                          <h3 className="text-lg font-bold leading-6 text-gray-900">
                            Was suchen Sie?
                          </h3>
                          <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
                            Geben Sie an, was Sie für ein Objekt suchen.
                          </p>
                        </div>
                        <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-2">
                          <CheckboxCard
                            label="Haus"
                            name="Objekt"
                            value="Haus"
                            id="haus"
                            svg={svg.house}
                          />

                          <CheckboxCard
                            label="Wohnung"
                            name="Objekt"
                            value="Wohnung"
                            id="wohnung"
                            svg={svg.complex}
                          />
                          <CheckboxCard
                            label="Gewerbeeinheit"
                            name="Objekt"
                            value="Gewerbeeinheit"
                            id="gewerbe"
                            svg={svg.business}
                          />
                          <CheckboxCard
                            label="Grundstück"
                            name="Objekt"
                            value="Grundstück"
                            id="grundstueck"
                            svg={svg.square}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="mt-8 mb-4">
                          <h3 className="text-lg font-bold leading-6 text-gray-900">
                            Wollen Sie kaufen oder mieten?
                          </h3>
                          <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
                            Geben Sie an, wie Sie das Objekt finanzieren wollen.
                          </p>
                        </div>
                        <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-2">
                          <CheckboxCard
                            label="Kaufen"
                            name="Kaufen"
                            value="Ja"
                            id="kaufen"
                            svg={svg.buy}
                          />

                          <CheckboxCard
                            label="Mieten"
                            name="Mieten"
                            value="Ja"
                            id="mieten"
                            svg={svg.rent}
                          />
                        </div>
                      </div>

                      <div>
                        <div className="mt-8 mb-4">
                          <h3 className="text-lg font-bold leading-6 text-gray-900">
                            Objektdaten
                          </h3>
                          <p className="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
                            Geben Sie an, was Ihnen wichtig ist. Sie müssen
                            nicht alle Felder ausfüllen.
                          </p>
                        </div>

                        <div className="grid grid-cols-2 gap-3 mb-5">
                          <Input
                            type="text"
                            label="Wunschlage"
                            id="wunschlage"
                            name="wunschlage"
                            placeholder="Musterstadt"
                          />

                          <Input
                            type="number"
                            label="Zimmer"
                            id="zimmer"
                            name="zimmer"
                            placeholder="5"
                            inputMode="numeric"
                          />

                          <Input
                            type="number"
                            label="Preisvorstellung in €"
                            id="preis"
                            name="preis"
                            placeholder="1.000"
                            inputMode="numeric"
                          />

                          <Input
                            type="number"
                            label="Wohnfläche in qm"
                            id="wohnflaeche"
                            name="wohnflaeche"
                            placeholder="100"
                            inputMode="numeric"
                          />
                        </div>
                      </div>

                      <MainData />
                    </Wizard>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <div className="flex-col lg:flex">
              <button onClick={goBack} className="inline-flex w-full mb-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-3 text-brand"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <circle cx="12" cy="12" r="9" />
                  <line x1="8" y1="12" x2="16" y2="12" />
                  <line x1="8" y1="12" x2="12" y2="16" />
                  <line x1="8" y1="12" x2="12" y2="8" />
                </svg>{" "}
                Zurück zur letzten Seite
              </button>

              <div className="w-full mb-5 text-left">
                <Blurb
                  svg={svg.check}
                  title="Kostenfreier Suchauftrag"
                  text="Da wir unsere Leistungen erst nach Erfolg in Rechnung stellen, haben Sie auch kein Risiko."
                />
              </div>
              <div className="w-full mb-5 text-left">
                <Blurb
                  svg={svg.check}
                  title="Schnelle Reaktionen"
                  text="Wir rufen Sie zeitnah an um alle Details zu besprechen."
                />
              </div>
              <div className="w-full mb-5 text-left">
                <Blurb
                  svg={svg.check}
                  title="Datenschutz"
                  text="Ihre Daten werden nur verwendet um die Anfrage zu bearbeiten. Wir geben die Daten nicht weiter oder verwenden diese anderweitig."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
